import mastersAPI from "@/api/mastersAPI.js";
import store from "@/store/index";
import validations from "@/validations/index";

export default {
  components: {},
  data: () => ({
    formData: {
      id: null,
      code: null,
      description: null,
    },
    loading: true,

    states: [],
    comment: false,
  }),
  props: ["dialog"],
  methods: {
    SaveData() {

       //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.formData.code);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.description);
      }

      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      mastersAPI
        .saveDiagnosis(this.formData)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.formData = {};

            if (response.data.status) {
              this.dialog.show = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
    async remove() {
      this.loading = true;

      await new Promise((resolve) => setTimeout(resolve, 3000));

      this.loading = false;
    },
  },
  created() {
    //console.log(this.vrState);
  },
  beforeMount() {
    //console.log(this.vrState);
  },
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
  mounted() {},
};
