import mastersAPI from "@/api/mastersAPI.js";
import store from "@/store/index";
import validations from "@/validations/index"; 

export default {
  components: {},
  data: () => ({
    formData: {
      doctor_id: null,
      first_name: null,
      last_name: null,
      address1: null,
      address2: null,
      city: null,
      state_code: null,
      zipcode: null,
      phone_num: null,
      e_mail: null,
    },
    loading: true,

    states: [],
    //dialog: true,
    dialog1: false,
    comment: false,
  }),
  props: ["dialog"],
  methods: {
    SaveData() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.formData.first_name);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.last_name);
      }

      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      mastersAPI
        .saveDoctor(this.formData)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.formData = {};

            this.dialog.show = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
  },
  created() {
    //console.log(this.vrState);
  },
  beforeMount() {
    //console.log(this.vrState);
  },
  computed: {
    vrState() {
      return store.state.arrStates;
    },
    vrNotify() {
      return store.state.notify;
    },
  },
  mounted() {},
};
