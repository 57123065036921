import apiClient from "./apiClient";
//import constant from './constants';

export default {
  saveDoctor(data) {
    return apiClient.post("masters/addUpdateDoctorInfo", data);
  },
  saveDiagnosis(data) {
    return apiClient.post("masters/addUpdateDiagnosis", data);
  },
  saveInsuranceMaster(data) {
    return apiClient.post("scheduling/patientInsuranceMasterSave", data);
  },
  saveExtendedCareFacility(data) {
    return apiClient.post("masters/AddUpdateExtndCareFacility", data);
  },
};
