import notifications from "@/components/common/notifications";
import SubHeader from "@/components/SubHeader.vue";
import Header from "@/components/Header.vue";
import AddDoctor from "@/components/masters/doctor.vue";
import AddDiagnosis from "@/components/masters/diagnosis.vue";
import store from "@/store/index";
import patientAPI from "@/api/patientAPI.js";
import mastersAPI from "@/api/mastersAPI.js";
import listAPI from "@/api/listAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import validations from "@/validations/index";
import permissions from "@/api/permissions.js";
import router from "@/router/index.js";
import DialogDrag from "vue-dialog-drag";

export default {
  components: {
    Header,
    SubHeader,
    notifications,
    AddDoctor,
    AddDiagnosis,
    DialogDrag,
  },
  data: () => ({
    // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    //   .toISOString()
    //   .substr(0, 10),
    show: true,
    nursing: false,
    states: [],
    data: [],
    patientsStay: [],
    items: [],

    isPostBack: false,
    vrState: [],
    vrDiagnosis: [],
    vrStatus: [],
    isLoadingDiagnosis: false,

    vrKeyWordDiagnosis1: null,
    vrKeyWordDiagnosis2: null,
    vrKeyWordDiagnosis3: null,
    vrDiagnosis1: [],
    vrDiagnosis2: [],
    vrDiagnosis3: [],
    vrKeyWordExtendedCareFacility: null,
    vrExtendedCareFacility: [],

    vrDoctorsRef_Items: [],
    vrExtendedCareFacility_Items: [],
    vrDoctorsOrd: [],
    vrDoctorsImp: [],
    vrKeyWordvrDoctorsRef: null,
    vrKeyWordvrDoctorsOrd: null,
    vrKeyWordvrDoctorsImp: null,
    vrDoctorsRef_model: {},
    vrDoctorsOrd_model: {},
    vrDoctorsImp_model: {},

    vrExtendedCareFacility_model: {},

    isLoadingDoctor: false,
    model_doctor: {},

    formData: {
      id: null, //RecordID
      is_my_patient: "F",
      reminder_opt_in: "F",
    },

    //Happy Fox
    happyFoxPopup: false,
    happyFox: {
      happyfox_display_id: "",
      enrolmentId: "ABC-001",
      rawData: "raw data...",
      errorData: "error data...",
    },

    formComments: {
      id: null,
      patient_id: null,
      comment: null,
      comment_type: null,
    },
    formFacility: {},
    comment_type: "G",
    addCommentLabel: "",
    search_string: "",
    enrollmentModel: false,
    searchTxtLabel: "search comments..",

    errorType: "",
    message: "",
    snackbar: false,
    loading: false,

    // Dialog Start
    dialog: { show: false },
    dialogDiagnosis: { show: false },
    comment: false,
    status: null,

    loadSavePatient: false,
    // Table data--------------------------------------------
    vrPatientComments: [],
    vrPatientReports: [],

    vrSex: [
      { sex_description: "Female", sex: "F" },
      { sex_description: "Male", sex: "M" },
    ],

    options: { page: 1 },
    sortBy: "create_date",
    sortDesc: true,
    totalRecords: null,
    tblLoading: false,
    tab: 0,
    headers: [
      {
        sortable: true,
        text: "DATE/TIME ",
        value: "create_date",
        width: "15%",
      },
      { sortable: false, text: "NAME", value: "full_name", width: "15%" },
      { sortable: false, text: "COMMENTS", value: "comment", width: "50%" },
      { sortable: false, text: "", value: "permission", width: "20%" },
    ],

    reportHeaders: [
      {
        sortable: false,
        text: "COMMENT",
        value: "file_comment",
        width: "40%",
      },
      { sortable: false, text: "FILE NAME", value: "file_name", width: "20%" },
      { sortable: false, text: "DOWNLOAD", value: "id", width: "8%" },
      { sortable: false, text: "UPDATED BY", value: "full_name", width: "12%" },
      {
        sortable: false,
        text: "UPDATED DATE",
        value: "update_date",
        width: "12%",
      },
      { sortable: false, text: "", value: "id", width: "8%" },
    ],
    reportDialogBox: false,
    //SelectedTabReport: true,
    formReport: {},

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    allowAdd: true,
    allowAddNotes: true,
    allowDelete: true,
    allowEdit: true,
    //---------------------------------------------------
  }),
  computed: {
    DialogDoctor() {
      return store.getters.getDialogDoctor;
    },
    vrPatientId() {
      return store.state.patientId;
    },
    vrRoleId() {
      return store.getters.user.role_id;
    },
    vrNotify() {
      return store.state.notify;
    },
    vrRedirectURL() {
      return store.state.redirectURL;
    },
    vrPatientStayType() {
      return store.state.arrPatientStayType;
    },
  },
  mounted() {
    store.commit("getPatientStayType");

    if (this.vrPatientId !== null) {
      this.formData.id = this.vrPatientId;
      this.loadPatientDetails();
      //this.loadPatientComments();
    }
  },

  methods: {
    addFacilityPopup() {
      //this.formFacility = Object.assign({}, this.vrExtendedCareFacility_model);
      this.formFacility = {};
      this.formFacility.facility_id = null;
    },
    editFacilityPopup() {
      //this.comment = true;
      this.nursing = true;
      this.formFacility = Object.assign({}, this.vrExtendedCareFacility_model);

      ///Set value for state
      this.formFacility.state = {};
      this.formFacility.state.state_id = this.formFacility.state_id;
      this.formFacility.state.state_code = this.formFacility.state_code;
    },
    editCommentsPopup(item) {
      this.comment = true;
      this.formComments = Object.assign({}, item);
    },
    selectComments_Reports(commentType) {
      this.search_string = "";
      this.comment_type = commentType;

      if (commentType == "G") {
        this.searchTxtLabel = "search comments...";

        //This method is used to load the comments
        this.loadPatientComments();
      }

      if (commentType == "C") {
        this.searchTxtLabel = "search notes...";

        //This method is used to load the comments
        this.loadPatientComments();
      }

      //Case for Reports
      if (commentType == "R") {
        this.searchTxtLabel = "search reports...";

        //This method is used to load the comments
        this.loadPatientReports();
      }
    },
    searchComments_Reports() {
      //Case for Reports
      if (this.comment_type == "R") {
        //This method is used to load the reports
        this.loadPatientReports();
      } else {
        //This method is used to load the comments
        this.loadPatientComments();
      }
    },
    deletePatient() {
      //this.formData = item

      if (!confirm("Are you sure, you want to delete it?")) {
        return false;
      }

      patientAPI
        .deletePatient({
          PatientId: this.vrPatientId,
        })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            if (response.data.status) {
              store.state.patientId = null;

              if (this.vrRedirectURL == null) {
                this.vrRedirectURL = "search";
              }
              router.push(this.vrRedirectURL);
            }
          }
        })
        .then(() => {
          //this.loadPatientScheduling();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    deleteComment(commentId) {
      //this.formData = item

      if (!confirm("Are you sure, you want to delete it?")) {
        return false;
      }

      patientAPI
        .deleteComment({
          id: commentId,
        })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            if (response.data.status) {
              this.loadPatientComments();
            }
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    savePatientDetails: function () {
      //e.preventDefault();

      //----------------------------------------------
      //Required field validations
      let isValid = true;

      isValid = validations.hasValue(this.formData.code);

      if (isValid) {
        isValid = validations.hasValue(this.formData.first_name);
      }
      if (isValid) {
        isValid = validations.hasValue(this.formData.last_name);
      }

      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      this.loadSavePatient = true;
      this.formData.ref_doctor = this.vrDoctorsRef_model;
      this.formData.ord_doctor = this.vrDoctorsOrd_model;
      this.formData.imp_doctor = this.vrDoctorsImp_model;
      //Extended Care Facility
      this.formData.facility = this.vrExtendedCareFacility_model;

      patientAPI
        .saveDemographics(this.formData)
        .then((response) => {
          if (response.data.results !== null) {
            if (response.data.status) {
              if (this.formData.id == null) {
                this.formData.id = response.data.results.id;
                store.state.patientId = response.data.results.id;
              }

              this.allowDelete = true;
              store.state.patientInfo.first_name = this.formData.first_name;
              store.state.patientInfo.last_name = this.formData.last_name;
            }
          }

          //Show notification to user
          if (response.data !== null) {
            //Load all comments again
            //this.loadPatientComments();

            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadSavePatient = false;
          //this[vrLoadingControlName] = false;
        });
    },

    saveComments() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid && !validations.hasValue(this.formComments.comment)) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return;
      }
      //----------------------------------------------
      this.formComments.patient_id = this.vrPatientId;
      this.formComments.comment_type = this.comment_type;

      patientAPI
        .saveComments(this.formComments)
        .then((response) => {
          if (response.data !== null) {
            if (response.data.status) {
              this.formComments = {};
              //Load patient comments again
              this.loadPatientComments();
              this.comment = false;
            }

            //Show notification to user
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },
    saveExtendedCareFacility() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;
      let vrState_code = "";

      if (isValid && !validations.hasValue(this.formFacility.facility_name)) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return;
      }

      if (this.formFacility.state != null) {
        vrState_code = this.formFacility.state.state_code;
        this.formFacility.state_id = this.formFacility.state.state_id;
        this.formFacility.state_code = vrState_code;
      } else {
        this.formFacility.state_id = null;
        this.formFacility.state_code = null;
      }

      //----------------------------------------------
      mastersAPI
        .saveExtendedCareFacility(this.formFacility)
        .then((response) => {
          if (response.data !== null) {
            if (response.data.status) {
              this.nursing = false;

              //Case for new facility
              if (this.formFacility.facility_id == null) {
                this.formFacility.facility_id = response.data.results.id;
              }
              this.vrExtendedCareFacility = [];
              this.vrExtendedCareFacility.push(this.formFacility);
              this.vrExtendedCareFacility_model = this.formFacility;
              //this.formFacility = {};
            }

            //Show notification to user
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },

    enrollmentComplete() {
      patientAPI
        .enrollmentComplete({ patient_id: this.formData.id })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            if (response.data.status) {
              this.formData.auto_enroll_complete_dt =
                response.data.results.auto_enroll_complete_dt;
              this.formData.auto_enroll_complete_flag = "T";
              this.formData.status_id = response.data.results.status_id;
            }
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    //-------------------------------------------------------
    //Load Patient Information
    //-------------------------------------------------------
    loadPatientDetails() {
      patientAPI
        .getPatientById({ PatientId: this.formData.id })
        .then((response) => {console.log(response.data)
          if (response.data.results !== null) {
            
            if (!response.data.status) {
              this.vrNotify.type = response.data.error_type;
              this.vrNotify.message = response.data.message;
              this.vrNotify.visible = true;

              //Case when patient has already deleted
              if (response.data.results.id == null) {
                store.state.redirectURL = "search";
                router.push("search").catch(() => {});
              }
            }

            this.formData = response.data.results;

            store.state.patientInfo.first_name = this.formData.first_name;
            store.state.patientInfo.last_name = this.formData.last_name;
          }
        })
        .then(() => {
          if (this.formData != null) {
            //Extended Care Facility

            if (
              this.formData.facility !== null &&
              this.formData.facility.facility_id !== null
            ) {
              this.vrExtendedCareFacility.push(this.formData.facility);
              this.vrExtendedCareFacility_model = this.formData.facility;
            }

            if (this.formData.diagnosis1.diagnosis_description !== null) {
              this.vrDiagnosis1.push(this.formData.diagnosis1);
              //this.formData.diagnosis1 = this.formData.diagnosis1
              // this.vrKeyWordDiagnosis1 =
              //   this.formData.diagnosis1.diagnosis_description;
            }

            if (this.formData.diagnosis2.diagnosis_description !== null) {
              this.vrDiagnosis2.push(this.formData.diagnosis2);
              // this.vrKeyWordDiagnosis2 =
              // this.formData.diagnosis2.diagnosis_description;
            }

            if (this.formData.diagnosis3.diagnosis_description !== null) {
              this.vrDiagnosis3.push(this.formData.diagnosis3);
              // this.vrKeyWordDiagnosis3 =
              //   this.formData.diagnosis3.diagnosis_description;
            }
          }
        })
        .then(() => {
          if (this.formData != null) {
            //Referring Doctor
            if (this.formData.ref_doctor.doctor_full_name !== null) {
              this.vrDoctorsRef_Items.push(this.formData.ref_doctor);
              this.vrDoctorsRef_model = this.formData.ref_doctor;
              // this.vrKeyWordvrDoctorsRef =
              //   this.formData.ref_doctor.doctor_full_name;
            }

            //Ordering Doctor
            if (this.formData.ord_doctor.doctor_full_name !== null) {
              this.vrDoctorsOrd.push(this.formData.ord_doctor);
              this.vrDoctorsOrd_model = this.formData.ord_doctor;
              // this.vrKeyWordvrDoctorsOrd =
              //   this.formData.ord_doctor.doctor_full_name;
            }

            //Implanting Doctor
            if (this.formData.imp_doctor.doctor_full_name !== null) {
              this.vrDoctorsImp.push(this.formData.imp_doctor);
              this.vrDoctorsImp_model = this.formData.imp_doctor;
              // this.vrKeyWordvrDoctorsImp =
              //   this.formData.imp_doctor.doctor_full_name;
            }
          }
        })
        .then(() => {
          this.isPostBack = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this.isLoadingDoctor = false;
        });
      this.tblLoading = false;
    },

    handleCommentReportPopup() {
      this.formComments = {};
      this.formReport = {};

      if (this.comment_type == "G" || this.comment_type == "C") {
        this.comment = true;
        this.reportDialogBox = false;
      } else {
        this.comment = false;
        this.reportDialogBox = true;
      }
    },

    loadPatientComments() {
      this.tblLoading = true;

      patientAPI
        .getPatientComments({
          comment_type: this.comment_type,
          PatientId: this.formData.id,
          search_string: this.search_string,
          pagination: this.options,
        })
        .then((response) => {
          if (response.data.results != null) {
            if (this.comment_type == "G") {
              this.addCommentLabel = "Add Comment";
            } else {
              this.addCommentLabel = "Add Notes";
            }

            //this.allowAdd = response.data.results.permission.allow_add;
            this.allowAddNotes = response.data.results.permission.allow_add;
            this.vrPatientComments = response.data.results.Comments;
            this.totalRecords = response.data.results.CommentCount.total_count;
          }
        })
        .finally(() => {
          this.tblLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadPatientReports() {
      this.tblLoading = true;
      //comment_type: this.comment_type,
      reportsAPI
        .getPatientReports({
          patient_id: this.formData.id,
          search_string: this.search_string,
          pagination: this.options,
        })
        .then((response) => {
          if (response.data.results != null) {
            this.allowAddNotes = response.data.results.permission.allow_add;
            //this.allowAdd = response.data.results.permission.allow_add;
            this.vrPatientReports = response.data.results.reports;
            this.totalRecords = response.data.results.ReportCount.total_count;

            this.addCommentLabel = "Add Report";
          }
        })
        .finally(() => {
          this.tblLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadReport() {
      //e.preventDefault();
      let isValid = true;
      //this.loading = true;
      this.vrNotify.visible = false;

      //Required validation for file and affiliate id
      if (
        !!this.formReport.file == false ||
        !!this.formReport.remarks == false
      ) {
        isValid = false;
      }

      if (!isValid) {
        //Show error message
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        this.loading = false;
        return false;
      }

      //Make data to popst  as Form-Data
      var formDataUpload = new FormData();
      //formDataUpload.append("id", null);
      formDataUpload.append("patient_id", this.formData.id);
      formDataUpload.append("file", this.formReport.file);
      formDataUpload.append("file_comment", this.formReport.remarks);

      reportsAPI
        .uploadPatientReport(formDataUpload)
        .then((response) => {
          if (response != null && response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            if (response.data.status) {
              this.formReport = {};
              this.loadPatientReports();
              this.reportDialogBox = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteReport(reportId) {
      //this.formData = item

      if (!confirm("Are you sure, you want to delete it?")) {
        return false;
      }

      reportsAPI
        .deletePatientReport({
          id: reportId,
          patient_id: this.formData.id,
        })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            //Reload the reports
            if (response.data.status) {
              this.loadPatientReports();
            }
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    downloadReport(item) {
      this.exportLoading = true;

      reportsAPI
        .downloadReport(
          "reports/download",
          { id: item.id, patient_id: this.formData.id },
          item.file_name
        )
        .then(() => {
          //this.exportLoading = false;
        });
    },
    //-------------------------------------------------------
    //Search Diagnosis
    //-------------------------------------------------------
    searchDiagnosis(val, vrControlName, vrLoadingControlName) {
      //Do not load data on initial page load

      if (!this.isPostBack && this.formData.id !== null) return;

      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      if (val === null) return; //Return

      this[vrLoadingControlName] = true; //initiate loading

      listAPI
        .getDiagnosisList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null)
            this[vrControlName] = response.data.results.Diagnosis;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this[vrLoadingControlName] = false;
        });

      this[vrLoadingControlName] = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------

    //-------------------------------------------------------
    //Search Doctor
    //-------------------------------------------------------
    searchDoctor(val, vrControlName, vrModelName, vrLoadingControlName) {
      //Do not load data on initial page load
      //if (!this.isPostBack) return;

      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      this.isLoadingDoctor = true; //initiate loading

      if (val === null) this[vrModelName] = {};

      if (val !== null) {
        listAPI
          .getDoctorsList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this[vrControlName] = response.data.results.Doctors;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDoctor = false;
          });
      }

      this.isLoadingDoctor = false;
    },
    //-------------------------------------------------------

    //-------------------------------------------------------
    //Extended Care Facility
    //-------------------------------------------------------
    searchExtendedCareFacility(
      val,
      vrControlName,
      vrModelName,
      vrLoadingControlName
    ) {
      //Do not load data on initial page load

      //if (!this.isPostBack && this.formData.id !== null) return;

      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      //if (val === null) return; //Return
      if (val === null) {
        this[vrModelName] = {};
        return;
      }

      this[vrLoadingControlName] = true; //initiate loading

      listAPI
        .getExtndCareFacilityList({ search_string: val })
        .then((response) => {
          if (response.data.results !== null)
            this[vrControlName] = response.data.results.facility;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this[vrLoadingControlName] = false;
        });

      this[vrLoadingControlName] = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------

    handleHappyFoxPopup() {
      patientAPI
        .getEnrolmentRawData({
          patient_id: this.vrPatientId,
        })
        .then((response) => {
          if (response.data !== null) {
            this.happyFoxPopup = true;
            if (response.data.results != null) {
              //response.data.results
              this.happyFox = response.data.results;
            } else {
              this.happyFox = [];
            }
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    opt_in_out() {
      let ltReminder_opt_in = this.formData.reminder_opt_in;

      //Toggle the condition
      if (ltReminder_opt_in == "F") {
        ltReminder_opt_in = "T";
      } else {
        ltReminder_opt_in = "F";
      }

      patientAPI
        .saveOpt_In_Opt_Out({
          patient_id: this.vrPatientId,
          reminder_opt_in: ltReminder_opt_in,
        })
        .then((response) => {
          if (response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.formData.reminder_opt_in = ltReminder_opt_in;
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    //----------------------------------------------
    //----------------------------------------------
    add_remove_favourite() {
      let lt_my_patient = this.formData.is_my_patient;

      //Toggle the condition
      if (lt_my_patient == "F") {
        lt_my_patient = "T";
      } else {
        lt_my_patient = "F";
      }

      patientAPI
        .savefavourite({
          patient_id: this.vrPatientId,
          is_my_patient: lt_my_patient,
        })
        .then((response) => {
          if (response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.formData.is_my_patient = lt_my_patient;
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          store.dispatch("getFavoritePatientList");
        });
    },
    //----------------------------------------------
  },

  watch: {
    options: {
      handler() {
        this.loadPatientComments();
      },
      deep: true,
    },
    formData: {
      handler: function () {
        //store.state.unSavedChanges = true;
      },
      deep: true,
    },
    //-------------------------------------------------------
    //Search Diagnosis-1
    //-------------------------------------------------------
    vrKeyWordDiagnosis1(val) {
      this.searchDiagnosis(val, "vrDiagnosis1", "isLoadingDiagnosis");
    },

    //-------------------------------------------------------
    //Search Diagnosis-2
    //-------------------------------------------------------
    vrKeyWordDiagnosis2(val) {
      this.searchDiagnosis(val, "vrDiagnosis2", "isLoadingDiagnosis");
    },

    //-------------------------------------------------------
    //Search Diagnosis-3
    //-------------------------------------------------------
    vrKeyWordDiagnosis3(val) {
      this.searchDiagnosis(val, "vrDiagnosis3", "isLoadingDiagnosis");
    },

    //-------------------------------------------------------
    //Search Doctor Referring
    //-------------------------------------------------------
    vrKeyWordvrDoctorsRef(val) {
      this.searchDoctor(
        val,
        "vrDoctorsRef_Items",
        "vrDoctorsRef_model",
        "isLoadingDiagnosis"
      );
    },

    //-------------------------------------------------------
    //Search Doctor Ordering
    //-------------------------------------------------------
    vrKeyWordvrDoctorsOrd(val) {
      this.searchDoctor(
        val,
        "vrDoctorsOrd",
        "vrDoctorsOrd_model",
        "isLoadingDiagnosis"
      );
    },

    //-------------------------------------------------------
    //Search Doctor Implanting
    //-------------------------------------------------------
    vrKeyWordvrDoctorsImp(val) {
      this.searchDoctor(
        val,
        "vrDoctorsImp",
        "vrDoctorsImp_model",
        "isLoadingDiagnosis"
      );
    },

    //-------------------------------------------------------
    //Extended Care Facility
    //-------------------------------------------------------
    vrKeyWordExtendedCareFacility(val) {
      this.searchExtendedCareFacility(
        val,
        "vrExtendedCareFacility",
        "vrExtendedCareFacility_model",
        "isLoadingDiagnosis"
      );
    },
  },
  async beforeMount() {
    await Promise.all([
      listAPI
        .getPatientSearchMasters()
        .then((response) => {
          this.vrState = response.data.results.States;
          this.vrStatus = response.data.results.Status;
          this.vrRemoteService = response.data.results.RemoteServices;
        })
        .catch((error) => {
          console.log(error);
        }),
    ]);
  },
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");

    //this.allowAdd = this.permissions.allowAdd;
    this.allowDelete = this.permissions.allowDelete;
    //alert(this.vrPatientId)
    if (this.vrPatientId == null) {
      this.allowEdit = true;
      this.allowDelete = false;
    } else if (this.vrPatientId != null && this.vrRoleId == 3) {
      //--Viewer Role
      this.allowEdit = false;
      this.allowAdd = false;
    }

    //Load stores value
    store.dispatch("getStates");
  },
};
